import { render, staticRenderFns } from "./index.vue?vue&type=template&id=3732d80e&"
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLangSwitcher: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/9/a/NuxtApp/components/app/LangSwitcher.vue').default,AppHeaderQuickLinks: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/9/a/NuxtApp/components/app/header/QuickLinks.vue').default,BaseCollapseTransition: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/9/a/NuxtApp/components/base/CollapseTransition.vue').default,AppHeaderMenuBtn: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/9/a/NuxtApp/components/app/header/MenuBtn.vue').default,AppHeaderMenuBar: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/9/a/NuxtApp/components/app/header/MenuBar.vue').default,AppHeaderSearchInput: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/9/a/NuxtApp/components/app/header/SearchInput.vue').default,AppHeaderUserDropdown: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/9/a/NuxtApp/components/app/header/UserDropdown.vue').default,AppHeaderFavorite: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/9/a/NuxtApp/components/app/header/Favorite.vue').default,AppHeaderShoppingCartBtn: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/9/a/NuxtApp/components/app/header/ShoppingCartBtn.vue').default,AppBreadcrumbs: require('/home/ubuntu/FirstFocusAgents/CVL-NUC-AGENT-1/_work/9/a/NuxtApp/components/app/Breadcrumbs.vue').default})
